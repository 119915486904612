import styled from "styled-components";


interface IconProps {
  type?: string;
  color?: string;
  width?: string;
  height?: string;
}

const Icon = styled.svg`
  font-size: 20px !important;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
`;


const EmissionSourceIcon = ({ type, width, height }: IconProps) => (
  <Icon
    style={{
      width: width ? width : "50px",
      height: height ? height : "50px",
      transform:
        type === "button" ? "translate(-50%, -50%)" : "translate(-50%, -100%)",
    }}
    viewBox="0 0 2600 2600"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    fill="#007BFF" // Cor preta para o ícone
  >
    <g transform="translate(0.000000,2600.000000) scale(0.100000,-0.100000)">
      <path d="M7810 24929 c-744 -43 -1426 -443 -1842 -1079 -180 -274 -310 -634 
      -359 -990 -20 -140 -17 -468 5 -615 22 -146 72 -345 120 -480 45 -125 147 
      -333 219 -447 l54 -86 -37 -114 c-250 -749 -40 -1585 538 -2139 357 -343 
      802 -541 1303 -580 259 -20 554 18 799 102 63 22 123 42 133 46 14 4 17 -1 
      17 -35 0 -56 27 -261 46 -352 119 -567 432 -1062 889 -1405 323 -243 676 
      -390 1085 -452 159 -24 492 -24 650 0 224 35 484 112 670 201 30 14 61 26 67 
      26 7 0 22 -19 34 -42 99 -195 318 -427 524 -555 178 -112 420 -195 631 -218 
      l60 -7 24 -70 c53 -157 197 -335 353 -436 175 -114 406 -169 602 -143 315 42 
      576 224 714 498 173 345 115 750 -151 1039 l-37 40 30 86 c60 174 73 263 74 
      483 0 158 -4 218 -19 285 -38 176 -110 367 -183 484 l-31 50 24 47 c73 145 
      151 382 187 569 29 155 32 532 4 680 -84 452 -263 808 -567 1124 -308 321 
      -705 529 -1170 612 -104 19 -415 25 -529 11 -57 -7 -63 -7 -57 8 21 56 48 
      259 53 400 9 252 -25 474 -109 715 -348 994 -1428 1549 -2421 1246 l-49 -15 
      -21 52 c-47 115 -140 288 -215 402 -470 708 -1263 1103 -2112 1054z"
      />
      <path d="M11840 13615 l0 -625 205 0 204 0 -5 -47 c-5 -53 -784 -11091 -784 
      -11117 0 -15 237 -16 2745 -16 2235 0 2745 2 2745 13 0 19 -778 11053 -784 
      11115 l-5 52 205 0 204 0 0 625 0 625 -2365 0 -2365 0 0 -625z"
      />
    </g>
  </Icon>
);
export default EmissionSourceIcon;