import React from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setPassword, setUsername } from "../features/login/loginSlice";
import { useServices } from "../context/ServicesContext";
import {
  setSnackBarOpen,
  setSnackbarData,
} from "../features/snackbar/snackbar";
import { setLoading } from "../features/loading/loadingSlice";
import { Container, Box, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import CustomizedSnackbar from "../components/SnackBar/SnackBar";

const style = {
  color: "rgba(255, 255, 255, 0.7)",
  ".MuiInputLabel-root": {
    "&.Mui-focused": {
      color: "rgba(255, 255, 255, 0.7)",
    },
  },
  ".MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "rgba(255, 255, 255, 0.7)",
    },
  },
};

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const { loginService } = useServices();
  const { username, password } = useAppSelector((state) => state.login);
  const { isOpen, snackbarData } = useAppSelector((state) => state.snackbar);
  const navigate = useNavigate();

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUsername(event.target.value));
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPassword(event.target.value));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    dispatch(setLoading(true));
    await loginService
      .login(username, password)
      .then((data) => {
        sessionStorage.setItem("access_token", data.access);
        sessionStorage.setItem("refresh_token", data.refresh);
        dispatch(setUsername(""));
        dispatch(setPassword(""));
        dispatch(setLoading(false));
        navigate("/");
      })
      .catch((err: any) => {
        dispatch(setLoading(false));
        dispatch(setSnackBarOpen(true));
        dispatch(setSnackbarData({ message: err.message, severity: "error" }));
      });
  };

  return (
    <>
      <Loader />
      <CustomizedSnackbar
        open={isOpen}
        message={snackbarData.message}
        severity={snackbarData.severity}
      />
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="/aires.png"
            alt="logo"
            style={{
              width: "400px", // 🔹 Ajuste o tamanho conforme necessário
              height: "auto", // 🔹 Mantém a proporção original
              marginBottom: "20px", // 🔹 Dá um espaçamento entre a logo e o formulário
            }}
          />
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              sx={style}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleUsernameChange}
            />
            <TextField
              sx={style}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handlePasswordChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Login;
