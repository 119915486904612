import { Dispatch, SetStateAction } from "react";
import { grey } from "@mui/material/colors";
import { Container, IconButton, Typography } from "@mui/material";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../../../app/hooks";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { parse, format } from "date-fns";

interface MenuNavProps {
  open: boolean;
  setOpen: ActionCreatorWithPayload<boolean, "plume/setOpen">;
  plumeDate: string;
}

export default function MenuNav({ open, setOpen, plumeDate }: MenuNavProps) {
  const dispatch = useAppDispatch();
  let dayDate: string;

  plumeDate !== ""
    ? (dayDate = format(
        parse(plumeDate, "yyMMddHHmm", new Date()),
        "d 'de' MMM 'de' yyyy',' HH:mm 'BRT'"
      ))
    : (dayDate = format(new Date(), "d 'de' MMM 'de' yyyy',' HH:mm 'BRT'"));

  return (
<Container
  sx={{
    width: "100%", // Ocupar toda a largura da tela
    display: "flex", // Ativar flexbox para organizar elementos na horizontal
    alignItems: "center", // Centralizar verticalmente os itens dentro do menu
    justifyContent: "space-between", // Distribuir logo à esquerda e botão à direita
    minHeight: "70px", // Aumenta a altura do menu para um visual mais destacado
    backgroundColor: "#0A161F", // Cor de fundo caso a imagem não carregue
    backgroundImage: "url('/assets/airesviewer-logo-final.png')", // Define a imagem da logo como fundo
    backgroundSize: "cover", // Faz a imagem cobrir toda a área do menu
    backgroundPosition: "center", // Centraliza a imagem no background
    overflow: "hidden", // Garante que a imagem de fundo siga o formato arredondado do menu
    maxWidth: "100%", // Limita a largura para não ficar colado nas laterais
  }}
>
  {/* Ícone de abrir/fechar menu */}
  <IconButton
    onClick={() => dispatch(setOpen(!open))}
    sx={{
      color: "white", // Cor do ícone
      backgroundColor: open ? "rgba(255, 255, 255, 0.1)" : "transparent", // Efeito de hover ao abrir/fechar
      borderRadius: "50%", // Mantém o botão com cantos arredondados
      padding: "10px", // Aumenta o tamanho do botão para um melhor clique
    }}
  >
    {open ? <ArrowDropUp /> : <ArrowDropDown />}
  </IconButton>
</Container>




  );
}
