import React, { useState, useEffect } from "react";
import { Polygon as LeafletPolygon } from "react-leaflet";
import { LatLngExpression } from "leaflet";
import * as d3Contour from "d3-contour";
import { useAppSelector } from "../../app/hooks";
import Legend, { LEGEND_CONFIG } from "./Containers/Legend";

export const CONFIG = {
  RESOLUTION: 55,
  POWER: 4,
  DEFAULT_METHOD: "IDW" as InterpolationMethod,
  UNIT_TYPE: "g/m³",
  UNIT_CONVERSION: 1e6,
  USE_SCIENTIFIC_NOTATION: false,
  SMOOTHING_FACTOR: 0.0,
  ENABLE_ANIMATION: true,
  ANIMATION_SPEED: 60,
  ANIMATION_FRAMES: 30,
  FIXED_DISPLAY_TIME: 3000,
  PLUME_OPACITY: 0.4,
};

CONFIG.UNIT_CONVERSION = CONFIG.UNIT_TYPE === "ug/m³" ? 1e6 : 1;

interface UTMPoint {
  lat: number;
  lon: number;
  c: number;
}

type InterpolationMethod = "IDW" | "nearest";

function interpolateGrid(points: UTMPoint[], rows: number, cols: number, method: InterpolationMethod, p: number = CONFIG.POWER) {
  if (!points || points.length < 3) {
    console.error("⚠️ ERRO: Não há pontos suficientes para interpolação.");
    return null;
  }

  const latMin = Math.min(...points.map((p) => p.lat));
  const latMax = Math.max(...points.map((p) => p.lat));
  const lonMin = Math.min(...points.map((p) => p.lon));
  const lonMax = Math.max(...points.map((p) => p.lon));

  const values = new Float64Array(rows * cols);

  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < cols; col++) {
      const lat = latMin + (row / (rows - 1)) * (latMax - latMin);
      const lon = lonMin + (col / (cols - 1)) * (lonMax - lonMin);
      let cellValue = 0;

      switch (method) {
        case "IDW": {
          let num = 0,
            den = 0;
          for (const pt of points) {
            const dist = Math.hypot(lat - pt.lat, lon - pt.lon);
            const w = dist === 0 ? 1e9 : 1 / Math.pow(dist, p);
            num += pt.c * w;
            den += w;
          }
          cellValue = den !== 0 ? num / den : 0;
          break;
        }

        case "nearest": {
          let minDist = Infinity,
            nearestVal = 0;
          for (const pt of points) {
            const dist = Math.hypot(lat - pt.lat, lon - pt.lon);
            if (dist < minDist) {
              minDist = dist;
              nearestVal = pt.c;
            }
          }
          cellValue = nearestVal;
          break;
        }
      }

      values[row * cols + col] = isNaN(cellValue) ? 0 : cellValue * CONFIG.UNIT_CONVERSION;
    }
  }

  return { values, rows, cols, latMin, latMax, lonMin, lonMax };
}

export default function LinePlumeLayer() {
  const { plume, plumeNumber } = useAppSelector((state) => state.plume);
  const [minIntensity, setMinIntensity] = useState<number | null>(null);
  const [maxIntensity, setMaxIntensity] = useState<number | null>(null);
  const [gridData, setGridData] = useState<any>(null);
  const [thresholds, setThresholds] = useState<number[]>([]);
  const [animationFrame, setAnimationFrame] = useState(CONFIG.ANIMATION_FRAMES - 1);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (plume && plumeNumber !== -1) {
      setMinIntensity(
        Math.max(plume.result[plumeNumber]?.min ?? CONFIG.UNIT_CONVERSION ?? LEGEND_CONFIG.MIN_THRESHOLD, LEGEND_CONFIG.MIN_THRESHOLD)
      );

      setMaxIntensity(
        plume.result[plumeNumber]?.max !== undefined
          ? plume.result[plumeNumber].max * CONFIG.UNIT_CONVERSION
          : LEGEND_CONFIG.MIN_THRESHOLD * 10
      );
    }
  }, [plume, plumeNumber]);

  useEffect(() => {
    if (minIntensity !== null && maxIntensity !== null) {
      setThresholds(LEGEND_CONFIG.getThresholds(minIntensity, maxIntensity, LEGEND_CONFIG.COLOR_SCHEME.length));
    }
  }, [minIntensity, maxIntensity]);

  useEffect(() => {
    if (plume && plumeNumber !== -1) {
      const points = plume.result[plumeNumber]?.plume as UTMPoint[];
      if (points?.length >= 3) {
        const result = interpolateGrid(points, CONFIG.RESOLUTION, CONFIG.RESOLUTION, CONFIG.DEFAULT_METHOD, CONFIG.POWER);
        if (result) {
          setGridData(result);
        }
      }
    }
  }, [plume, plumeNumber]);

  useEffect(() => {
    if (CONFIG.ENABLE_ANIMATION) {
      const interval = setInterval(() => {
        setAnimationFrame((prev) => {
          if (prev <= 0) {
            setIsPaused(true);
            setTimeout(() => {
              setIsPaused(false);
              setAnimationFrame(CONFIG.ANIMATION_FRAMES - 1);
            }, CONFIG.FIXED_DISPLAY_TIME);
            return 0;
          }
          return prev - 1;
        });
      }, CONFIG.ANIMATION_SPEED);

      return () => clearInterval(interval);
    }
  }, []);

  if (!gridData || !gridData.values) {
    console.warn("⚠️ Nenhum dado válido para renderizar a pluma.");
    return null;
  }

  const { values, rows, cols, latMin, latMax, lonMin, lonMax } = gridData;

  return (
    <>
      {CONFIG.ENABLE_ANIMATION ? (
        thresholds.slice(animationFrame).map((threshold, index) => (
          <LeafletPolygon
            key={index}
            positions={
              d3Contour
                .contours()
                .size([cols, rows])
                .thresholds([threshold])(values)
                .flatMap(contour =>
                  contour.coordinates.map(polygon =>
                    polygon.map(ring =>
                      ring.map(([x, y]) => [
                        latMin + (y / (rows - 1)) * (latMax - latMin),
                        lonMin + (x / (cols - 1)) * (lonMax - lonMin),
                      ])
                    )
                  )
                ) as LatLngExpression[][][]
            }
            pathOptions={{
              color: LEGEND_CONFIG.COLOR_SCHEME[index] || "#ccc",
              fillOpacity: isPaused ? CONFIG.PLUME_OPACITY : (animationFrame + 1) / CONFIG.ANIMATION_FRAMES,
              weight: 1,
            }}
          />
        ))
      ) : (
        thresholds.map((threshold, index) => (
          <LeafletPolygon
            key={index}
            positions={
              d3Contour
                .contours()
                .size([cols, rows])
                .thresholds([threshold])(values)
                .flatMap(contour =>
                  contour.coordinates.map(polygon =>
                    polygon.map(ring =>
                      ring.map(([x, y]) => [
                        latMin + (y / (rows - 1)) * (latMax - latMin),
                        lonMin + (x / (cols - 1)) * (lonMax - lonMin),
                      ])
                    )
                  )
                ) as LatLngExpression[][][]
            }
            pathOptions={{
              color: LEGEND_CONFIG.COLOR_SCHEME[index] || "#ccc",
              fillOpacity: CONFIG.PLUME_OPACITY,
              weight: 1,
            }}
          />
        ))
      )}

      <Legend thresholds={thresholds} minIntensity={minIntensity ?? LEGEND_CONFIG.MIN_THRESHOLD} maxIntensity={maxIntensity ?? LEGEND_CONFIG.MIN_THRESHOLD * 10} unit={CONFIG.UNIT_TYPE} useScientificNotation={CONFIG.USE_SCIENTIFIC_NOTATION} />
    </>
  );
}
