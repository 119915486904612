import React from "react";
import styled from "styled-components";

interface LegendConfig {
  COLOR_SCHEME: string[];
  USE_CUSTOM_SPACING: boolean;
  CUSTOM_SPACING: number[];
  AUTO_SPACING: boolean;
  MIN_THRESHOLD: number;
  DEFAULT_BANDS: number;
  getThresholds: (min: number, max: number, numBands?: number) => number[];
}

export const LEGEND_CONFIG: LegendConfig = {
  COLOR_SCHEME: ["#140f69", "#104c54", "#0b8f2c", "#25dd1b", "#5ccc06", "#e6c224", "#ec761e", "#b8392a"],
  USE_CUSTOM_SPACING: false,
  CUSTOM_SPACING: [20, 200, 300, 400, 500, 600, 800, 2000],
  AUTO_SPACING: true,
  MIN_THRESHOLD: 15,
  DEFAULT_BANDS: 10,
  getThresholds: (min: number, max: number, numBands: number = LEGEND_CONFIG.DEFAULT_BANDS) => {
    if (LEGEND_CONFIG.USE_CUSTOM_SPACING) {
      return LEGEND_CONFIG.CUSTOM_SPACING.filter((v) => !isNaN(v) && v > 0);
    }

    if (!LEGEND_CONFIG.AUTO_SPACING || isNaN(min) || isNaN(max) || min >= max) {
      return [];
    }

    min = Math.max(min, LEGEND_CONFIG.MIN_THRESHOLD);
    max = Math.max(max, min + 1);

    const thresholds: number[] = [];
    
    // Definir os valores logarítmicos de min e max
    const logMin = Math.log10(min);
    const logMax = Math.log10(max);

    // Distribuir os thresholds igualmente no espaço logarítmico
    for (let i = 0; i < numBands; i++) {
      const logValue = logMin + ((logMax - logMin) * (i / (numBands - 1)));
      const value = Math.pow(10, logValue);
      thresholds.push(value);
    }

    return thresholds;
  },
};

interface LegendProps {
  thresholds?: number[];
  minIntensity: number;
  maxIntensity: number;
  unit: string;
  useScientificNotation: boolean;
}

const Container = styled.div<{ numBands: number }>`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  //background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ numBands }) => Math.max(numBands * 30, 250)}px;
  min-width: 1000px;
  max-width: 90%;
`;

const Gradient = styled.div`
  display: flex;
  width: 100%;
  height: 25px;
  border-radius: 4px;
  overflow: hidden;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 6px;
`;

const Label = styled.span`
  font-size: 12px;
  font-weight: bold;
`;

const UnitLabel = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-top: 6px;
`;

export default function Legend({ thresholds, minIntensity, maxIntensity, unit, useScientificNotation }: LegendProps) {
  let numBands = thresholds?.length ?? LEGEND_CONFIG.DEFAULT_BANDS;

  if (!thresholds || thresholds.length === 0) {
    console.warn("⚠️ Nenhum threshold recebido, gerando valores padrão.");
    thresholds = LEGEND_CONFIG.getThresholds(minIntensity, maxIntensity, numBands);
    numBands = thresholds.length;
  }

  return (
    <Container numBands={numBands}>
      <Gradient>
        {LEGEND_CONFIG.COLOR_SCHEME.slice(0, numBands).map((color: string, index: number) => (
          <div key={index} style={{ backgroundColor: color, flex: 1 }} />
        ))}
      </Gradient>

      <LabelContainer>
        {thresholds.map((value: number, index: number) => (
          <Label key={index}>{useScientificNotation ? value.toExponential(2) : value.toFixed(2)}</Label>
        ))}
      </LabelContainer>
      <UnitLabel>{unit}</UnitLabel>
    </Container>
  );
}
