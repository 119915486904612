import ReactDOM from "react-dom/client";
import "./styles/index.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PlumeService } from "./service/PlumeService";
import { ServicesProvider } from "./context/ServicesContext";
import { Client } from "./service/Client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { FacilitiesService } from "./service/FacilitiesService";
import { ForecastService } from "./service/ForecastService";
import { EmissionSourceService } from "./service/EmissionSourceService";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { OccurrenceService } from "./service/OccurrenceService";
import { PlacesService } from "./service/PlacesService";
import App from "./pages/App";
import { LoginService } from "./service/LoginService";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#1E3A8A", // Azul escuro para botões e títulos
    },
    secondary: {
      main: "#F3F4F6", // Cinza claro para os cards
    },
    background: {
      default: "#FFFFFF", // Fundo branco geral
      paper: "#F9FAFB", // Fundo dos cards
    },
    text: {
      primary: "#000000", // Texto preto
      secondary: "#4B5563", // Texto cinza escuro
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "10px", // Bordas arredondadas para os cards
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "18px",
          fontWeight: "bold",
          textTransform: "none",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF", // Fundo branco
          borderRadius: "8px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF", // Fundo branco dos dropdowns
          borderRadius: "8px",
          border: "1px solid #D1D5DB",
          color: "#000000", // Texto preto
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#FFFFFF", // Fundo branco do menu dropdown
          color: "#000000", // Texto preto
          borderRadius: "8px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#1E3A8A", // Azul escuro para os checkboxes
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#007BFF", // Ícones padrão brancos
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#1E3A8A", // Ícones dentro de botões em azul escuro
        },
      },
    },
  },
});



function getToken() {
  let access_token = sessionStorage.getItem("access_token");
  if (access_token) {
    return access_token;
  }
} 

const url = process.env.REACT_APP_SERVER_PATH;

if (!url) {
  console.warn("REACT_APP_SERVER_PATH não está definido.");
}

const client = new Client(
  url ?? '',
  getToken
);

const plumeService = new PlumeService(client);
const facilitiesService = new FacilitiesService(client);
const forecastService = new ForecastService(client);
const emissionSourceService = new EmissionSourceService(client);
const occurrenceService = new OccurrenceService(client);
const placesService = new PlacesService(client);
const loginService = new LoginService(client);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={lightTheme}>
      <ServicesProvider
        plumeService={plumeService}
        facilitiesService={facilitiesService}
        forecastService={forecastService}
        emissionSourceService={emissionSourceService}
        occurrenceService={occurrenceService}
        placesService={placesService}
        loginService={loginService}
      >
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </LocalizationProvider>
      </ServicesProvider>
    </ThemeProvider>
  </Provider>
);

