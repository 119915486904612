import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Box,
  Button,
} from "@mui/material";
import UmbrellaIcon from "@mui/icons-material/Umbrella";
import CloudIcon from "@mui/icons-material/Cloud";
import AirIcon from "@mui/icons-material/Air";
import { styled } from "@mui/material/styles";
import { Dispatch, SetStateAction } from "react";
import { format, parse } from "date-fns";
import { useAppDispatch } from "../../../app/hooks";
import { setLoading } from "../../../features/loading/loadingSlice";
import { ForecastData } from "../../../interfaces/Forecast";

interface ForecastingCardProps {
  forecastData: ForecastData;
  dateKey: string;
  fetchForecastData: (period: string) => void;
  setForecastDay: Dispatch<SetStateAction<string | undefined>>;
}

const Item = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  line-height: 10px;
  padding: 4px 4px;
  min-height: 37px;
`;

const ForecastItem = ({
  icon,
  data,
  label,
}: {
  icon: JSX.Element;
  data: number | string;
  label: string;
}) => (
  <Grid item xs={4} sx={{ borderTop: "1px solid" }}>
    <Item>
      {icon}
      {data} {label}
    </Item>
  </Grid>
);

function ForecastingCard({
  forecastData,
  dateKey,
  fetchForecastData,
  setForecastDay,
}: ForecastingCardProps) {
  const dispatch = useAppDispatch();

  return (
    <Card
  sx={{
    margin: 2,
    backgroundColor: "#FFFFFF", // Fundo branco
    backgroundImage: "none",
    border: "1px solid #D1D5DB", // Borda cinza suave
    borderRadius: "10px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Sombra leve para destacar
  }}
>
  <CardHeader
    sx={{
      color: "#000000", // Texto preto
      backgroundColor: "#F3F4F6", // Cinza claro
      padding: "10px 15px",
      borderBottom: "1px solid #D1D5DB",
      span: { fontSize: "14px", fontWeight: "bold" },
    }}
    title={format(parse(dateKey, "yyyyMMddHH", new Date()), "cccc MMMM dd, yyyy")}
    titleTypographyProps={{ align: "center" }}
  />
  <CardContent
    sx={{
      padding: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:last-child": { paddingBottom: "10px" },
    }}
  >
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <Typography p={1} sx={{ fontSize: "30px", fontWeight: 700, color: "#000000" }}>
          {forecastData.temp}°C
        </Typography>
      </Grid>
      <Grid item container xs={4} md={2} lg={7}>
        <ForecastItem icon={<AirIcon fontSize="small" sx={{ color: "#007BFF" }} />} data={forecastData.ws} label="m/s" />
        <ForecastItem icon={<UmbrellaIcon fontSize="small" sx={{ color: "#007BFF" }} />} data={forecastData.pamt} label="mm" />
        <ForecastItem icon={<CloudIcon fontSize="small" sx={{ color: "#007BFF" }} />} data={forecastData.ccvr} label="%" />
        <Grid item xs={12} sx={{ borderTop: "1px solid #D1D5DB" }}>
          <Item sx={{ color: "#000000" }}>Wind Direction: {forecastData.wd}</Item>
        </Grid>
        <Grid item xs={12} sx={{ borderTop: "1px solid #D1D5DB" }}>
          <Item sx={{ color: "#000000" }}>Barometric Pressure: {forecastData.pres} hpa</Item>
        </Grid>
        <Grid item xs={12} sx={{ borderTop: "1px solid #D1D5DB" }}>
          <Item sx={{ color: "#000000" }}>Cloud coverage: {forecastData.ccvr}%</Item>
        </Grid>
        <Grid item xs={12} sx={{ borderTop: "1px solid #D1D5DB" }}>
          <Item sx={{ color: "#000000" }}>Relative Humidity: {forecastData.rh}%</Item>
        </Grid>
      </Grid>
      <Button
        sx={{
          maxHeight: "30px",
          fontSize: "10px",
          borderRadius: "18px",
          fontWeight: "700",
          backgroundColor: "#007BFF", // Azul para destacar o botão
          color: "#FFFFFF", // Texto branco
          "&:hover": {
            backgroundColor: "#0056b3",
          },
        }}
        variant="contained"
        onClick={() => {
          dispatch(setLoading(true));
          fetchForecastData("hours");
          setForecastDay(dateKey);
        }}
      >
        Hourly Forecast
      </Button>
    </Grid>
  </CardContent>
</Card>

  );
}

export default ForecastingCard;
