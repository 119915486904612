import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { setViewPoints } from "../../../../../features/plume/plumeSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";

interface OptionsProps {
  setPlumeStyle: (value: boolean) => void;
  plumeStyle: boolean;
  setActiveIcons: (value: boolean) => void;
  activeIcons: boolean;
}

export function Options({
  setPlumeStyle,
  plumeStyle,
  setActiveIcons,
  activeIcons,
}: OptionsProps) {
  const dispatch = useAppDispatch();
  const [optionsVisible, setOptionsVisible] = useState(true);
  const { pointView } = useAppSelector((state) => state.plume);

  return (
    <Card
      sx={{
        backgroundColor: "#282c2e",
      }}
    >
      <CardHeader
        sx={{
          cursor: "pointer",
          color: "#000000",
          backgroundColor: "#F3F4F6",
          padding: "5px 15px 5px 15px",
          span: {
            fontSize: "12px",
            fontWeight: "bold",
          },
        }}
        title=""
        action={optionsVisible ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={() => setOptionsVisible(!optionsVisible)}
      />
      
    </Card>
  );
}