import { Box, Grid, Typography } from "@mui/material";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import SpeedIcon from "@mui/icons-material/Speed";
import UmbrellaIcon from "@mui/icons-material/Umbrella";
import CloudIcon from "@mui/icons-material/Cloud";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import AirIcon from "@mui/icons-material/Air";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import { styled } from "@mui/material/styles";

const Item = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  textAlign: "center",
  fontSize: "9px",
  lineHeight: "10px",
  padding: "4px 4px",
}));

interface WeatherProps {
  plumeInfo: any;
}

export default function Weather({ plumeInfo }: WeatherProps) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        {plumeInfo ? (
          <>
            <Grid
              item
              xs={5}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* ☀ Sol - Amarelo/Dourado */}
              <WbSunnyIcon sx={{ fontSize: { xs: "45px" }, color: "#0A2E5C" }} />
            </Grid>
            <Grid item container xs={7}>
              <Grid item xs={6} sx={{ border: "1px solid", borderTop: "none" }}>
                <Item>
                  {/* 🌡 Temperatura - Vermelho */}
                  <ThermostatIcon sx={{ fontSize: { xs: "15px" }, color: "#0A2E5C" }} />{" "}
                  {plumeInfo.temp} °C
                </Item>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  border: "1px solid",
                  borderTop: "none",
                  borderRight: "none",
                }}
              >
                <Item>
                  {/* 💧 Precipitação - Azul */}
                  <UmbrellaIcon sx={{ fontSize: { xs: "15px" }, color: "#0A2E5C" }} />
                  {plumeInfo.precipitation} mm
                </Item>
              </Grid>
              <Grid item xs={6} sx={{ border: "1px solid" }}>
                <Item>
                  {/* 📏 Pressão Atmosférica - Roxo */}
                  <SpeedIcon sx={{ fontSize: { xs: "15px" }, color: "#0A2E5C" }} />
                  {plumeInfo.pressure} hpa
                </Item>
              </Grid>
              <Grid item xs={6} sx={{ border: "1px solid", borderRight: "none" }}>
                <Item>
                  {/* ☁ Cobertura de Nuvens - Cinza */}
                  <CloudIcon sx={{ fontSize: { xs: "15px" }, color: "#0A2E5C" }} />{" "}
                  {plumeInfo.cloudCover}%
                </Item>
              </Grid>
              <Grid item xs={6} sx={{ border: "1px solid" }}>
                <Item>
                  {/* 🧭 Direção do Vento - Verde */}
                  <NorthEastIcon sx={{ fontSize: { xs: "15px" }, color: "#0A2E5C" }} />
                  {plumeInfo.windDirection}
                </Item>
              </Grid>
              <Grid item xs={6} sx={{ border: "1px solid", borderRight: "none" }}>
                <Item>
                  {/* 🌬️ Velocidade do Vento - Azul Claro */}
                  <AirIcon sx={{ fontSize: { xs: "15px" }, color: "#0A2E5C" }} />
                  {plumeInfo.windSpeed.toFixed(4)} m/s
                </Item>
              </Grid>
              <Grid item xs={6} sx={{ border: "1px solid", borderBottom: "none" }}>
                <Item>
                  {/* 💦 Umidade - Ciano */}
                  <WaterDropIcon sx={{ fontSize: { xs: "15px" }, color: "#0A2E5C" }} />
                  {plumeInfo.humidity}%
                </Item>
              </Grid>
              <Grid item xs={6} sx={{ border: "1px solid", borderBottom: "none", borderRight: "none" }}>
                <Item>
                  {/* ❗ Complaint Risk - Vermelho (caso necessário) */}
                  <Typography sx={{ fontSize: "9px", color: "#0A2E5C", fontWeight: "bold" }}>
                    Complaint Risk Not Applicable
                  </Typography>
                </Item>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              m={2}
              sx={{
                width: "100%",
                height: { xs: "103px" },
                textAlign: "center",
              }}
            >
              <Typography variant="h4">Select facility…</Typography>
            </Grid>
            <></>
          </>
        )}
      </Grid>
    </Box>
  );
}
