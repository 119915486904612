import { Dispatch, SetStateAction } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Card,
  CardHeader,
  CardContent,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
  Grid,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "60%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 34,
};

interface SettingsModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setPlumeStyle: (checked: boolean) => void;
  plumeStyle: boolean;
  setActiveIcons: (checked: boolean) => void;
  activeIcons: boolean;
  setIcons: ({
    facility,
    emissionSource,
    occurrence,
    places,
  }: {
    facility: boolean;
    emissionSource: boolean;
    occurrence: boolean;
    places: boolean;
  }) => void;
  icons: {
    facility: boolean;
    emissionSource: boolean;
    occurrence: boolean;
    places: boolean;
  };
}

export default function SettingsModal({
  open,
  setOpen,
  setPlumeStyle,
  plumeStyle,
  setActiveIcons,
  activeIcons,
  setIcons,
  icons,
}: SettingsModalProps) {
  const handleClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          backgroundColor: "#FFFFFF", // Fundo branco
          borderRadius: "10px", // Borda arredondada
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)", // Sombra leve para destaque
        }}
      >
        <Card
          sx={{
            backgroundColor: "#FFFFFF", // Mantendo fundo branco
            borderRadius: "10px",
            border: "1px solid #D1D5DB", // Adicionando borda leve
          }}
        >
          <CardHeader
            sx={{
              color: "#000000", // Texto preto
              backgroundColor: "#F3F4F6", // Cinza claro no cabeçalho
              padding: "10px 15px",
              borderBottom: "1px solid #D1D5DB",
              span: {
                fontSize: "14px",
                fontWeight: "bold",
              },
            }}
            title="Options"
          />
          <CardContent sx={{ padding: "15px", backgroundColor: "#FFFFFF" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  paddingTop: "5px",
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#000000", // Texto preto para melhor contraste
                }}
              >
                Icons
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={activeIcons}
                    onChange={() => setActiveIcons(!activeIcons)}
                    sx={{ color: "#1E40AF" }} // Azul no checkbox
                  />
                }
                label={
                  <Typography sx={{ fontSize: 12, color: "#000000" }}>
                    Hide All Icons
                  </Typography>
                }
              />
              <Grid container>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={icons.facility}
                        onChange={() =>
                          setIcons({ ...icons, facility: !icons.facility })
                        }
                        sx={{ color: "#1E40AF" }} // Azul nos checkboxes
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: 12, color: "#000000" }}>
                        Facility Icons
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={icons.emissionSource}
                        onChange={() =>
                          setIcons({
                            ...icons,
                            emissionSource: !icons.emissionSource,
                          })
                        }
                        sx={{ color: "#1E40AF" }} // Azul nos checkboxes
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: 12, color: "#000000" }}>
                        Emission Sources Icons
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={icons.occurrence}
                        onChange={() =>
                          setIcons({ ...icons, occurrence: !icons.occurrence })
                        }
                        sx={{ color: "#1E40AF" }} // Azul nos checkboxes
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: 12, color: "#000000" }}>
                        Occurrences Icons
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={icons.places}
                        onChange={() =>
                          setIcons({ ...icons, places: !icons.places })
                        }
                        sx={{ color: "#1E40AF" }} // Azul nos checkboxes
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: 12, color: "#000000" }}>
                        Places Icons
                      </Typography>
                    }
                  />
                  <Divider sx={{ width: "100%", color: "#D1D5DB" }} />
                  <Link
                    to="/login"
                    onClick={() => {
                      sessionStorage.removeItem("access_token");
                      sessionStorage.removeItem("refresh_token");
                    }}
                  >
                    <Button
                      style={{
                        marginTop: "10px",
                        textTransform: "none",
                        color: "#1E40AF", // Azul no link de logout
                      }}
                    >
                      Logout
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
  
}
