import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";
import Dropdown from "../../Dropdown/Dropdown";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setLoading } from "../../../features/loading/loadingSlice";
import { useServices } from "../../../context/ServicesContext";
import Point from "./EmissionFields/Point";
import Volume from "./EmissionFields/Volume";
import Areapoly from "./EmissionFields/Areapoly";
import Line from "./EmissionFields/Line";
import StyledTextField from "../StyledTextField";
import AreaLinePoints from "./EmissionFields/AreaLinePoints";
import Buttons from "./EmissionFields/Buttons";
import TextAreas from "./EmissionFields/TextAreas";

interface EmissionSource {
  name: string;
  facility: string;
  pollutant: string;
  is_odour: boolean;
  type: string;
  lat: string;
  lon: string;
  building: string;
  typeData: {
    ptemis?: number;
    stkhgt?: number;
    stktmp?: number;
    stkvel?: number;
    stkdia?: number;
    vlemis?: number;
    relhgt?: number;
    areapolySzinit?: number;
    areapolyRelhgt?: number;
    syinit?: number;
    szinit?: number;
    aremis?: number;
    lnemis?: number;
    width?: number;
    area?: number[][];
    line?: number[][];
  };
}

interface EmissionSourceDisplayProps {
  id?: string;
  setOpen: (value: boolean) => void;
}

function EmissionSourceDisplay({ id, setOpen }: EmissionSourceDisplayProps) {
  const dispatch = useAppDispatch();
  const { emissionSourceService } = useServices();
  const { emissionSourceUpdated } = useAppSelector(
    (state) => state.emissionSource
  );
  const { facilitiesStore } = useAppSelector((state) => state.facilities);
  const [selected, setSelected] = useState<number>(-1);
  const [typeSelected, setTypeSelected] = useState<number>(-1);
  const [isOdour, setIsOdour] = useState<number>(0);
  const [pollutant, setPollutant] = useState<number>(-1);
  const [step, setStep] = useState<number>(0);
  const [points, setPoints] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [isValidPreviousStep, setIsValidPreviousStep] =
    useState<boolean>(false);
  const [emissionSource, setEmissionSource] = useState<EmissionSource>(
    {} as EmissionSource
  );
  const types = [
    { name: "POINT" },
    { name: "VOLUME" },
    { name: "AREAPOLY" },
    { name: "LINE" },
  ];

  const pollutants = [
    { name: "PM_10" },
    { name: "PM_2.5" },
    { name: "TSP" },
    { name: "SO2" },
    { name: "CO" },
    { name: "NO2" },
    { name: "MERCAPTANA" },
    { name: "H2S" },
    { name: "NH3" },
    { name: "COV" },
    { name: "ODOR" },
  ];

  useEffect(() => {
    if (id) {
      dispatch(setLoading(true));
      emissionSourceService
        .getEmissionSource(id)
        .then((resp: EmissionSource) => {
          setEmissionSource(resp);
          setSelected(
            facilitiesStore.findIndex(
              (facility: { id: any }) => facility.id === resp.facility
            )
          );
          setTypeSelected(
            types.findIndex((type) => type.name === resp.type.toUpperCase())
          );
          setIsOdour(resp.is_odour ? 1 : 0);
          setPollutant(
            pollutants.findIndex(
              (pollutant) => pollutant.name === resp.pollutant.toUpperCase()
            )
          );

          if (
            resp.typeData &&
            ["LINE", "AREAPOLY"].includes(resp.type.toUpperCase())
          ) {
            const is_line = resp.type.toUpperCase() === "LINE";
            const obj_area_type = resp.typeData[is_line ? "line" : "area"];

            if (!obj_area_type) return;

            const points = obj_area_type.reduce(
              (previous, current: number[], index) => {
                return (
                  previous +
                  `${current[0]} ${current[1]}` +
                  (index === obj_area_type.length - 1 ? "" : "\n")
                );
              },
              ""
            );
            setPoints(points);
          }
          dispatch(setLoading(false));
        });
    }
  }, [id]);

  useEffect(() => {
    if (selected !== -1) {
      setEmissionSource((prevEmissionSource) => ({
        ...prevEmissionSource,
        facility: facilitiesStore[selected].id,
      }));
    }
    if (typeSelected !== -1) {
      console.log("emissioun source display", emissionSource.typeData);
      setEmissionSource((prevEmissionSource) => ({
        ...prevEmissionSource,
        type: types[typeSelected].name,
        typeData: id
          ? prevEmissionSource.typeData
          : {
              ptemis: 0,
              stkhgt: 0,
              stktmp: 0,
              stkvel: 0,
              stkdia: 0,
              vlemis: 0,
              relhgt: 0,
              syinit: 0,
              szinit: 0,
              aremis: 0,
              lnemis: 0,
              width: 0,
              area: [],
              line: [],
            },
      }));
    }
    if (isOdour !== -1) {
      setEmissionSource((prevEmissionSource) => ({
        ...prevEmissionSource,
        is_odour: isOdour === 0 ? false : true,
      }));
    }
    if (pollutant !== -1) {
      setEmissionSource((prevEmissionSource) => ({
        ...prevEmissionSource,
        pollutant: pollutants[pollutant].name,
      }));
    }
  }, [selected, typeSelected, isOdour, pollutant]);

  useEffect(() => {
    if ([2, 3].includes(typeSelected) && step !== 0) {
      const regex = /^(-*\d+\.\d+\s-*\d+\.\d+\n*)+$/;

      if (points === "" || !regex.test(points)) {
        setError(!error);
        setIsValidPreviousStep(false);
        setStep(step > 0 ? step - 1 : 0);
        return;
      }

      const points_schema = points
        .trim()
        .replaceAll(/\n+$/g, "")
        .split("\n")
        .map((point) => {
          const point_array = point.split(" ");

          return [[parseFloat(point_array[0]), parseFloat(point_array[1])]];
        })
        .flat(1);

      setEmissionSource((prevEmissionSource) => ({
        ...prevEmissionSource,
        typeData: {
          ...prevEmissionSource.typeData,
          [3 === typeSelected ? "line" : "area"]: points_schema,
        },
      }));
    }

    setIsValidPreviousStep(true);
  }, [step]);
  return (
    <>
      <Card sx={{ maxWidth: "845px" }}>
        <CardHeader
          sx={{ span: { fontWeight: "bold" } }}
          title={id ? "Editar Fonte de Emissões" : "Nova Fonte de Emissões"}
        />
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Grid
            container
            spacing={1}
            sx={{ maxHeight: "500px", overflowY: "auto", paddingRight: "5px" }}
          >
            {step === 0 && (
              <>
                <Grid item xs={6}>
                  <StyledTextField
                    placeholder="ex: nome_da_fonte"
                    label="Nome"
                    value={emissionSource.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setEmissionSource({
                        ...emissionSource,
                        name: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Dropdown
                    className="Projeto"
                    label={"Facility"}
                    options={facilitiesStore}
                    checkmarks={false}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </Grid>
                <Grid item xs={4} lg={5}>
                  <Dropdown
                    className="Tipo"
                    label={"Source Type"}
                    options={types}
                    checkmarks={false}
                    selected={typeSelected}
                    setSelected={setTypeSelected}
                    disabled={id ? true : false}
                  />
                </Grid>
                <Grid item xs={4} lg={5}>
                  <StyledTextField
                    placeholder="ex: PM_10"
                    label="Pollutant"
                    value={emissionSource.pollutant}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setEmissionSource({
                        ...emissionSource,
                        pollutant: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={4} lg={2}>
                  <FormControlLabel
                    value="Odor"
                    control={
                      <Checkbox
                        checked={
                          emissionSource.is_odour
                            ? emissionSource.is_odour
                            : Boolean(isOdour)
                        }
                        onChange={() => setIsOdour(isOdour === 0 ? 1 : 0)}
                      />
                    }
                    label="Odor"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledTextField
                    placeholder="ex: 99,999"
                    label="Center Latitude"
                    type="number"
                    value={emissionSource.lat}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setEmissionSource({
                        ...emissionSource,
                        lat: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledTextField
                    placeholder="ex: 99,999"
                    label="Center Longitude"
                    type="number"
                    value={emissionSource.lon}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setEmissionSource({
                        ...emissionSource,
                        lon: e.target.value,
                      });
                    }}
                  />
                </Grid>
                {typeSelected === 0 && (
                  <Point
                    emissionSource={emissionSource}
                    setEmissionSource={setEmissionSource}
                  />
                )}
                {typeSelected === 1 && (
                  <Volume
                    emissionSource={emissionSource}
                    setEmissionSource={setEmissionSource}
                  />
                )}
                {typeSelected === 2 && (
                  <Areapoly
                    emissionSource={emissionSource}
                    setEmissionSource={setEmissionSource}
                    id={id}
                  />
                )}
                {typeSelected === 3 && (
                  <Line
                    emissionSource={emissionSource}
                    setEmissionSource={setEmissionSource}
                  />
                )}
                {(typeSelected === 2 || typeSelected === 3) && (
                  <AreaLinePoints
                    error={error}
                    points={points}
                    setPoints={setPoints}
                    type={types[typeSelected].name}
                  />
                )}
              </>
            )}
            {isValidPreviousStep && step === 1 && (
              <TextAreas
                emissionSource={emissionSource}
                setEmissionSource={setEmissionSource}
              />
            )}
            <Buttons
              step={step}
              setStep={setStep}
              emissionSource={emissionSource}
              setEmissionSource={setEmissionSource}
              emissionSourceService={emissionSourceService}
              dispatch={dispatch}
              setOpen={setOpen}
              id={id}
              emissionSourceUpdated={emissionSourceUpdated}
            />
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default EmissionSourceDisplay;
