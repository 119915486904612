import { Box, IconButton, Tooltip } from "@mui/material";
import FactoryIcon from "@mui/icons-material/Factory";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useState } from "react";
import EmissionSourceIcon from "../../assets/icons/EmissionSource";
import CrudModal from "./CrudModal";
import { CenterMarker } from "../../assets/icons/Marker";

interface CrudProps {
  type: string;
  bottom: string;
}

function Crud({ type, bottom }: CrudProps) {
  const [open, setOpen] = useState(false);
  let tooltiptext = "";

  if (type === "Facility") {
    tooltiptext = "Add Facility";
  } else if (type === "EmissionSource") {
    tooltiptext = "Add Emission Source";
  } else if (type === "Occurrence") {
    tooltiptext = "Occurrence Panel";
  } else if (type === "Place") {
    tooltiptext = "Add Place";
  }

  return (
    <>
      <Tooltip title={tooltiptext} placement="left">
        <IconButton
          size="large"
          aria-label={tooltiptext}
          sx={{
            position: "absolute",
            top: bottom,
            right: "15px",
            zIndex: 500,
            bgcolor: "#0A161F",
            borderRadius: "8px",
            width: 60, // 🔹 Tamanho unificado para TODOS os botões
            height: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              backgroundColor: "rgb(28, 33, 35)",
            },
          }}
          onClick={() => setOpen(true)}
        >
          {type === "Facility" && <FactoryIcon sx={{ fontSize: 28, color: "white" }} />}
          {type === "EmissionSource" && (
            <Box width={28} height={28} display="flex" alignItems="center" justifyContent="center">
              <EmissionSourceIcon color="white" type="button" />
            </Box>
          )}
          {type === "Occurrence" && (
            <ErrorOutlineIcon sx={{ fontSize: 28, color: "#007BFF" }} /> // 🔹 Mantendo azul
          )}
          {type === "Place" && (
            <Box width={28} height={28} display="flex" alignItems="center" justifyContent="center">
              <CenterMarker fill="white" />
            </Box>
          )}
        </IconButton>
      </Tooltip>

      <CrudModal open={open} setOpen={setOpen} type={type} />
    </>
  );
}

export default Crud;
