import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import Dropdown from "../../../Dropdown/Dropdown";
import Slider from "../Slider/Slider";
import Weather from "../Weather/Weather";
import BasicDatePicker from "../../../DatePicker/DatePicker";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import MultiOptDropdown from "../../../Dropdown/MultiOptDropdown";
import { updatePlume } from "../../../../features/plume/plumeSlice";
import { useServices } from "../../../../context/ServicesContext";
import {
  addDays,
  addHours,
  differenceInHours,
  format,
  parse,
  set,
} from "date-fns";
import { setLoading } from "../../../../features/loading/loadingSlice";
import {
  setSnackBarOpen,
  setSnackbarData,
} from "../../../../features/snackbar/snackbar";
import { Options } from "./Options/Options";
import ModelData from "./ModelData/ModelData";
import { updateSelectFacilitie } from "../../../../features/facilities/facilitiesSlice";
import { ArrowDropUp, ArrowDropDown, HelpOutline } from "@mui/icons-material";
import { setSelectedEmissionSources } from "../../../../features/emissionSource/emissionSourceSlice";
interface MenuBodyProps {
  open: boolean;
  setPlumeStyle: Dispatch<SetStateAction<boolean>>;
  setActiveIcons: Dispatch<SetStateAction<boolean>>;
  plumeStyle: boolean;
  setPlumeDate: Dispatch<SetStateAction<string | undefined>>;
  activeIcons: boolean;
}

export default function MenuBody({
  open,
  setPlumeStyle,
  setActiveIcons,
  plumeStyle,
  setPlumeDate,
  activeIcons,
}: MenuBodyProps) {
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<number>(-1);
  const [emissionSources, setEmissionSources] = useState<any>();
  const [groupedEmissionSources, setGroupedEmissionSources] = useState<any>();
  const [checkmarkSelected, setCheckmarksSelected] = useState<any>([]);
  const [timeWindowHook, setTimeWindow] = useState<number>(-1);
  const timeWindow = [
    { name: "10m", value: "10_M" },
    { name: "1hr", value: 1 },
    { name: "8hr", value: 8 },
    { name: "24hr", value: 24 },
  ];
  const { plumeService } = useServices();
  const { facilitiesService } = useServices();
  const { facilitiesStore } = useAppSelector((state) => state.facilities);
  const { plume, storedData } = useAppSelector((state) => state.plume);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [isAuto, setIsAuto] = useState(false);
  const [lastStep, setLastStep] = useState<number>(1);
  const [sliderValue, setSliderValue] = useState(1);
  const [weatherVisible, setWeatherVisible] = useState(true);
  const [modelDataVisible, setModelDataVisible] = useState(true);
  const [timeframeVisible, setTimeframeVisible] = useState(true);
  const [timeWindowOptions, setTimeWindowOptions] = useState<any>(timeWindow);
  const { emissionSourceUpdated } = useAppSelector(
    (state) => state.emissionSource
  );
  const isFirstTimeAuto = useRef(true);
  const [disableDates, setDisableDates] = useState<boolean>(false);

  useEffect(() => {
    if (startDate && endDate) {
      let formatedEndDate = parse(
        format(endDate?.$d ? endDate?.$d : endDate, "yyyy-MM-dd HH:mm"),
        "yyyy-MM-dd HH:mm",
        new Date()
      );

      let formatedStartDate = parse(
        format(startDate?.$d ? startDate?.$d : startDate, "yyyy-MM-dd HH:mm"),
        "yyyy-MM-dd HH:mm",
        new Date()
      );

      const is24HoursDifference =
        differenceInHours(formatedEndDate, formatedStartDate) > 0 &&
        differenceInHours(formatedEndDate, formatedStartDate) <= 24;
      const is72HoursDifference =
        differenceInHours(formatedEndDate, formatedStartDate) >= 24 &&
        differenceInHours(formatedEndDate, formatedStartDate) <= 72;

      if (isAuto) {
        setTimeWindowOptions(
          timeWindow.filter(
            (option) => option.value !== 8 && option.value !== 24
          )
        );
      } else if (is24HoursDifference && !isAuto) {
        setTimeWindowOptions(
          timeWindow.filter((option) => option.value !== "10_M")
        );
      } else if (is72HoursDifference && !isAuto) {
        setTimeWindowOptions(
          timeWindow.filter(
            (option) => option.value !== 1 && option.value !== "10_M"
          )
        );
      } else {
        setTimeWindowOptions([]);
      }
    } else {
      setTimeWindowOptions([]);
    }
    // eslint-disable-next-line
  }, [endDate, startDate]);

  useEffect(() => {
    if (isAuto && isFirstTimeAuto.current) {
      setStartDate(new Date());
      setEndDate(addHours(new Date(), 1));
      setTimeWindowOptions(
        timeWindow.filter((option) => option.value !== 8 && option.value !== 24)
      );
      isFirstTimeAuto.current = false;
    } else {
      setTimeWindowOptions(
        timeWindow.filter((option) => option.value !== 8 && option.value !== 24)
      );
    }
  }, [isAuto]);

  const plumeDate = useMemo(
    () => setPlumeDate(plume?.result[sliderValue - 1]?.dateStart),
    // eslint-disable-next-line
    [sliderValue]
  );

  useEffect(() => {
    setLastStep(plume?.result?.length);
    if (storedData.time !== 0) {
      setSelected(storedData.facilityPos);
      setTimeWindow(timeWindow.findIndex((e) => e.value === storedData.time));
    }
    dispatch(setLoading(false));
    // eslint-disable-next-line
  }, [plume]);

  async function fetchEmissionSource() {
    dispatch(updateSelectFacilitie(selected));
    if (selected !== -1) {
      dispatch(setLoading(true));
      await facilitiesService
        .fetchEmissionSource(facilitiesStore[selected].id)
        .then((resp) => {
          setEmissionSources(
            resp.data.map((emission: any) => ({
              id: emission.id,
              name: emission.name,
              is_odour: emission.is_odour,
            }))
          );
          setGroupedEmissionSources(
            resp.data.reduce(
              (groups: { [x: string]: any[] }, source: { pollutant: any }) => {
                const key = source.pollutant;
                if (!groups[key]) {
                  groups[key] = [];
                }
                groups[key].push(source);
                return groups;
              },
              {}
            )
          );
          dispatch(setLoading(false));
          if (storedData) {
            setCheckmarksSelected(
              resp.data
                .filter((emission: any) =>
                  storedData.emissionSources.includes(emission.id)
                )
                .map((emission: any) => emission.name)
            );
          }
        })
        .catch((err) => {
          dispatch(setSnackBarOpen(true));
          dispatch(
            setSnackbarData({
              message: err.message,
              severity: "error",
            })
          );
          dispatch(setLoading(false));
        });
    }
  }

  async function fetchPlume() {
    dispatch(setLoading(true));
    await plumeService
      .fetchPlume(
        facilitiesStore[selected].id,
        getSelectedEmissionIDs(),
        startDate !== undefined
          ? format(startDate?.$d ? startDate?.$d : startDate, "yyyy-MM-dd HHmm")
          : undefined,
        endDate !== undefined
          ? format(endDate?.$d ? endDate?.$d : endDate, "yyyy-MM-dd HHmm")
          : undefined,
        timeWindowHook !== -1
          ? timeWindowOptions[timeWindowHook].value
          : undefined
      )
      .then((resp) => {
        if (resp.result.length === 0) {
          dispatch(setSnackBarOpen(true));
          dispatch(
            setSnackbarData({
              message: "No plume data found",
              severity: "error",
            })
          );
        }
        dispatch(setSelectedEmissionSources(getSelectedEmissionOdor()));
        dispatch(updatePlume(resp));
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setSnackBarOpen(true));
        dispatch(
          setSnackbarData({
            message: err.message,
            severity: "error",
          })
        );
        dispatch(setLoading(false));
      });
  }

  useEffect(() => {
    fetchEmissionSource();
    setStartDate(null);
    setEndDate(null);
    setTimeWindow(-1);
    // eslint-disable-next-line
  }, [selected, emissionSourceUpdated, facilitiesStore]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (isAuto && sliderValue === lastStep) {
      let newStartDate = addHours(startDate, 1);
      let newEndDate = addHours(endDate, 1);

      setStartDate(newStartDate);
      setEndDate(newEndDate);
      setIsAuto(false);
      intervalId = setTimeout(
        () => {
          fetchPlume().then(() => {
            setSliderValue(1);
            setIsAuto(true);
          });
        },
        isAuto && lastStep === 2 ? 3600000 : 600000
      );
    }

    return () => {
      clearTimeout(intervalId as NodeJS.Timeout);
    };
  }, [sliderValue]);

  function getSelectedEmissionIDs() {
    if (emissionSources && checkmarkSelected) {
      const selectedEmissionIDs = checkmarkSelected
        .map((selectedName: any) => {
          const selectedEmission = emissionSources.find(
            (emission: { name: string }) => emission.name === selectedName
          );
          return selectedEmission ? selectedEmission.id : null;
        })
        .filter((id: null) => id !== null);

      return {
        sources: selectedEmissionIDs,
      };
    }

    return { sources: [] }; // Return an empty array in the specified structure
  }

  function getSelectedEmissionOdor() {
    if (emissionSources && checkmarkSelected) {
      const selectedEmissionIDs = emissionSources.filter((emission: any) =>
        checkmarkSelected.includes(emission.name)
      );

      return {
        sources: selectedEmissionIDs,
      };
    }

    return { sources: [] };
  }

  return open ? (
    <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "90vh", backgroundColor: "#FFFFFF" }}>
      <Card
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "10px", // Adicionando cantos arredondados
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Sombra suave
        }}
      >
        <CardHeader
          sx={{
            cursor: "pointer",
            color: "#000000",
            backgroundColor: "#F3F4F6",
            padding: "10px 15px",
            borderBottom: "1px solid #D1D5DB", // Borda sutil para separar seções
            span: {
              fontSize: "14px",
              fontWeight: "bold",
            },
          }}
          title="Clima"
          action={weatherVisible ? <ArrowDropUp /> : <ArrowDropDown />}
          onClick={() => setWeatherVisible(!weatherVisible)}
        />
        {weatherVisible && (
          <CardContent
            sx={{
              padding: "10px",
              backgroundColor: "#FFFFFF",
              color: "#000000",
              "&:last-child": { paddingBottom: "10px" },
            }}
          >
            <Weather
              plumeInfo={plume ? plume.result[sliderValue - 1]?.meteorology : null}
            />
          </CardContent>
        )}
      </Card>

      <Card
  sx={{
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  }}
>
  <CardHeader
    sx={{
      cursor: "pointer",
      color: "#000000",
      backgroundColor: "#F3F4F6",
      padding: "10px 15px",
      borderBottom: "1px solid #D1D5DB",
      span: {
        fontSize: "14px",
        fontWeight: "bold",
      },
    }}
    title="Início/Fim"
    action={modelDataVisible ? <ArrowDropUp /> : <ArrowDropDown />}
    onClick={() => setModelDataVisible(!modelDataVisible)}
  />
  {modelDataVisible && (
    <CardContent
      sx={{
        padding: "10px",
        backgroundColor: "#FFFFFF",
        color: "#000000",
        "&:last-child": { paddingBottom: "10px" },
      }}
    >
      {/* Envolvendo Dropdowns e DatePickers com Box para aplicar estilos */}
      <Box sx={{ "& label": { color: "#000000", fontWeight: "bold" } }}>
        <Dropdown label="Projetos" options={facilitiesStore} checkmarks={false} selected={selected} setSelected={setSelected} />
      </Box>

      <Box sx={{ "& label": { color: "#000000", fontWeight: "bold" } }}>
        <MultiOptDropdown
          label="Fontes de emissões"
          groupedOptions={groupedEmissionSources}
          checkmarkSelected={checkmarkSelected}
          setCheckmarksSelected={setCheckmarksSelected}
        />
      </Box>

      <Box sx={{ "& label": { color: "#000000", fontWeight: "bold" } }}>
        <BasicDatePicker value={storedData.startDate !== "" ? storedData.startDate : startDate} label="Início" setDate={setStartDate} disabled={disableDates} />
      </Box>

      <Box sx={{ "& label": { color: "#000000", fontWeight: "bold" } }}>
        <BasicDatePicker value={storedData.endDate !== "" ? storedData.endDate : endDate} label="Fim" setDate={setEndDate} disabled={disableDates} />
      </Box>

      <Box sx={{ "& label": { color: "#000000", fontWeight: "bold" } }}>
        <Dropdown label="Tempo" options={timeWindowOptions} disabled={timeWindowOptions.length === 0} checkmarks={false} selected={timeWindowHook} setSelected={setTimeWindow} />
      </Box>

      <Box
        sx={{
          backgroundColor: "#F3F4F6",
          color: "#000000",
          display: "flex",
          alignItems: "center",
          justifyContent: plume ? "space-between" : "flex-end",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        {plume && plume.success && plume.id && (
          <ModelData plumeId={plume.id} plumeService={plumeService} />
        )}

        <FormControlLabel
          sx={{
            maxHeight: { xs: "20px" },
            fontSize: { xs: "10px" },
            borderRadius: "18px",
            fontWeight: "700",
            marginBottom: "9px",
          }}
          control={
            <Checkbox
              onChange={() => {
                setIsAuto(!isAuto);
                setTimeframeVisible(isAuto);
                setDisableDates(!disableDates);
              }}
            />
          }
          label={<Typography sx={{ fontSize: 12, color: "#000000" }}>Auto</Typography>}
        />

        <Tooltip
          title="Start Date cannot be after End Date"
          sx={{ margin: "0 0 9px auto" }}
          placement="top-start"
        >
          <HelpOutline sx={{ color: "#000000" }} />
        </Tooltip>

        <Button
          sx={{
            backgroundColor: "#E5E7EB",
            color: "#000000",
            border: "1px solid #D1D5DB",
            maxHeight: { xs: "20px" },
            fontSize: { xs: "10px" },
            borderRadius: "18px",
            fontWeight: "700",
            margin: "0 0 9px 9px",
            "&:hover": {
              backgroundColor: "#D1D5DB",
            },
          }}
          variant="contained"
          disabled={selected === -1 || checkmarkSelected.length === 0 || timeWindowHook === -1}
          onClick={() => fetchPlume()}
        >
          Buscar
        </Button>
      </Box>
    </CardContent>
  )}
</Card>


      <Card
        sx={{
          backgroundColor: "#FFFFFF",
          overflow: "visible",
          borderRadius: "10px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardHeader
          sx={{
            cursor: "pointer",
            color: "#000000",
            backgroundColor: "#F3F4F6",
            padding: "10px 15px",
            borderBottom: "1px solid #D1D5DB",
            span: {
              fontSize: "14px",
              fontWeight: "bold",
            },
          }}
          title="Timeframe Navigation"
          action={timeframeVisible ? <ArrowDropUp /> : <ArrowDropDown />}
          onClick={() => (isAuto ? null : setTimeframeVisible(!timeframeVisible))}
        />
        <CardContent
          sx={{
            visibility: !timeframeVisible ? "hidden" : "visible",
            height: !timeframeVisible ? "0px" : "100%",
            padding: "10px",
            backgroundColor: "#FFFFFF",
            color: "#000000",
            "&:last-child": { paddingBottom: "10px" },
          }}
        >
          <Slider sliderValue={sliderValue} setSliderValue={setSliderValue} lastStep={lastStep ?? 1} plumeDate={plume?.result[sliderValue - 1]?.dateStart} isAuto={isAuto} />
        </CardContent>
      </Card>

      <Options setPlumeStyle={setPlumeStyle} plumeStyle={plumeStyle} setActiveIcons={setActiveIcons} activeIcons={activeIcons} />
    </div>
  ) : (
    <></>
  );
}
