import { IconButton, Tooltip } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useState } from "react";
import SettingsModal from "./SettingsModal";
//import AiresViewerIcon from "../../assets/icons/AiresViewerIcon"; // Importando o ícone SVG como componente React

interface SettingsProps {
  setPlumeStyle: (value: boolean) => void;
  plumeStyle: boolean;
  setActiveIcons: (value: boolean) => void;
  activeIcons: boolean;
  setIcons: ({
    facility,
    emissionSource,
    occurrence,
    places,
  }: {
    facility: boolean;
    emissionSource: boolean;
    occurrence: boolean;
    places: boolean;
  }) => void;
  icons: {
    facility: boolean;
    emissionSource: boolean;
    occurrence: boolean;
    places: boolean;
  };
}

function Settings({
  setPlumeStyle,
  plumeStyle,
  setActiveIcons,
  activeIcons,
  setIcons,
  icons,
}: SettingsProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      {/* Botão de Configurações (Settings) */}
      <Tooltip title="Settings" placement="left">
        <IconButton
          size="large"
          aria-label="Settings"
          sx={{
            position: "absolute",
            top: "310px",
            right: "15px",
            zIndex: 500,
            width: "60px",
            height: "60px",
            bgcolor: "#0A161F",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "rgb(28, 33, 35)",
            },
          }}
          onClick={() => setOpen(true)}
        >
           <SettingsIcon sx={{ fontSize: 34, color: "0A161F" }} /> {/* 🔹 Ajustando tamanho e cor */}
        </IconButton>
      </Tooltip>

      <Tooltip title="Aires Viewer" placement="left">
        <IconButton
          size="large"
          aria-label="Aires Viewer"
          sx={{
            position: "absolute",
            top: "382px",
            right: "15px",
            zIndex: 500,
            borderRadius: "8px",
            width: "60px",
            height: "60px",
            bgcolor: "#0A161F",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              backgroundColor: "rgb(28, 33, 35)",
            },
            //position: "relative", // Permite sobreposição do texto
          }}
          onClick={() => window.open("https://airesviewer.com.br/", "_blank")}
        >
          {/* Imagem */}
          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/logo AiresViewer.png`}
            alt="Aires Viewer"
            style={{
              width: "180%",
              height: "180%",
              objectFit: "contain",
              marginTop: "-3px",
            }}
          />
          
          {/* Texto sobreposto */}
          
        </IconButton>
      </Tooltip>

      {/* Modal de Configurações */}
      <SettingsModal
        open={open}
        setOpen={setOpen}
        setPlumeStyle={setPlumeStyle}
        plumeStyle={plumeStyle}
        setActiveIcons={setActiveIcons}
        activeIcons={activeIcons}
        setIcons={setIcons}
        icons={icons}
      />
    </>
  );
}

export default Settings;
